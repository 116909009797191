export const googleDriveConverter = url => {
  const id = url?.split("/")[5]
  return id && `https://drive.google.com/uc?export=download&id=${id}`
}

// export const parseRawText = text => {
//   return (
//     text &&
//     text.split("\n").map(textNode => (
//       <>
//         <span>{textNode}</span>
//         <br />
//       </>
//     ))
//   )
// }
