import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  Row,
  Title,
  H1Title,
  H2Title,
  H3Title,
  H4Title,
  Bold,
  NewsLink,
} from "../components/styled"
import styled from "styled-components"
import { colors } from "../constants/colors"
import { svgs } from "../components/svgs"
import { Map } from "../components/map"
import { mq } from "../constants/media-queries"
import { graphql } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"
import { googleDriveConverter } from "../utils/utils"

const InfoSection = styled(Section)`
  padding: 90px 0 30px;
  ${mq.sm`
  padding: 20vh 0 0;
  `}
`

const InfoRow = styled(Row)`
  display: grid;
  gap: 20px;
  ${mq.sm`
  grid-template-columns: 1fr auto;
  `}
`

const NewsBox = styled.div`
  background: ${colors.white};
  padding: 20px;
  line-height: 1.5;
  order: 1;
  ${mq.sm`
  order: 0;
  padding: 30px;
  `}
  p:not(:last-of-type) {
    margin-bottom: 14px;
  }
`

const ContactBox = styled.div`
  background: white;
  padding: 20px;
  ${mq.sm`
  padding: 26px 30px;
  `}
`

const Icon = styled.div`
  border: 1px solid ${colors.black4};
  border-radius: 99px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  svg {
    transition: all 0.2s ease;
    fill: ${colors.black4};
    height: 45%;
    width: 45%;
    object-fit: contain;
  }
`

const ContactIntro = styled.div`
  font-size: 50px;
  line-height: 1.3;
  font-family: "Oswald";
  margin-bottom: 20px;
`

const ContactOwner = styled.p`
  line-height: 1.5;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 500;
`

const ContactItem = styled.a`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
  transition: all 0.2s ease;
  letter-spacing: 0.5px;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &:hover,
  &:focus {
    color: ${colors.pink};
    ${Icon} {
      border: 1px solid ${colors.pink};
      svg {
        fill: ${colors.pink};
      }
    }
  }
`

const PlacesRow = styled(Row)`
  display: grid;
  gap: 50px;
`

const PlaceTitle = styled(Title)`
  margin-bottom: 0;
`

const navItems = [
  {
    title: "Danse",
    path: "/danse",
  },
  {
    title: "Fitness",
    path: "/fitness",
  },
]

const serializer = {
  types: {
    block: props => {
      const { style = "normal" } = props.node
      switch (style) {
        case "h1":
          return <H1Title>{props.children}</H1Title>
        case "h2":
          return <H2Title>{props.children}</H2Title>
        case "h3":
          return <H3Title>{props.children}</H3Title>
        case "h4":
          return <H4Title>{props.children}</H4Title>
        default:
          return <p>{props.children}</p>
      }
    },
  },
  marks: {
    strong: props => <Bold>{props.children}</Bold>,
    inlinefile: props => {
      switch (props.mark._type) {
        case "inlinefile":
          if (props.mark.asset) {
            return (
              <NewsLink
                target="_blank"
                rel="noopener noreferrer"
                href={`${props.mark.asset.url}?dl=`}
                alt={props.children[0]}
              >
                {props.children[0]}
              </NewsLink>
            )
          } else {
            return <p></p>
          }
        default:
          return <p></p>
      }
    },
    googledriveFile: props =>
      props.mark.href ? (
        <NewsLink
          target="_blank"
          rel="noopener noreferrer"
          href={googleDriveConverter(props.mark?.href)}
          alt={props.children[0]}
        >
          {props.children[0]}
        </NewsLink>
      ) : (
        <span></span>
      ),
  },
}

const InfoPage = ({ data }) => {
  return (
    <Layout navItems={navItems}>
      <SEO title="Infos" />
      <InfoSection hero>
        <InfoRow>
          <NewsBox>
            <SanityBlockContent
              blocks={data.sanityInfo._rawBody}
              serializers={serializer}
            />
          </NewsBox>
          <ContactBox>
            <ContactIntro>On s'appelle?</ContactIntro>
            <ContactOwner>Gene Francart</ContactOwner>
            <ContactItem href="tel:+32475931820">
              <Icon>{svgs.phone}</Icon>0475/93.18.20
            </ContactItem>
            <ContactItem href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#103;&#102;&#105;&#116;&#100;&#97;&#110;&#99;&#101;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;">
              <Icon>{svgs.email}</Icon>
              gfitdance@gmail.com
            </ContactItem>
          </ContactBox>
        </InfoRow>
      </InfoSection>
      <Section>
        <PlacesRow>
          <PlaceTitle>Les salles de cours</PlaceTitle>
          <Map
            id="lln"
            place="Louvain-la-Neuve"
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9546.773571378431!2d4.596811440770205!3d50.67143568802069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c17e0af24734e7%3A0x7366fca9c5719497!2sCentre%20Sportif%20de%20Blocry!5e0!3m2!1sfr!2sbe!4v1593811423395!5m2!1sfr!2sbe"
          ></Map>
          <Map
            id="msg"
            place="Mont-Saint-Guibert"
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4916.87977742759!2d4.603216897821962!3d50.63681956926624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c17e493f1221af%3A0x1bc42b3b088fcaf9!2sCentre%20Sportif%20Jean%20Moisse!5e0!3m2!1sfr!2sbe!4v1593811504931!5m2!1sfr!2sbe"
          ></Map>
          <Map
            id="nsv"
            place="Nil-Saint-Vincent - Salle Seuciau"
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6136.874359776658!2d4.651342052887608!3d50.6265057687189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c17ed89f6cc685%3A0xae12f659414f0ded!2sChauss%C3%A9e%20de%20Namur%2025%2C%201457%20Walhain!5e0!3m2!1sfr!2sbe!4v1600112999458!5m2!1sfr!2sbe"
          ></Map>
        </PlacesRow>
      </Section>
    </Layout>
  )
}

export default InfoPage

export const pageQuery = graphql`
  query {
    sanityInfo {
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`
