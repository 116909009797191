import React from "react"
import styled from "styled-components"
import { mq } from "../constants/media-queries"

const MapTitle = styled.h3`
  font-size: 30px;
  margin-bottom: 16px;
  font-family: "Oswald";
`

const ResponsiveMap = styled.div`
  overflow: hidden;
  padding-bottom: 45%;
  ${mq.sm`
  padding-bottom: 35%;
  `}
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

export const Map = ({ place, url }) => {
  return (
    <div>
      <MapTitle>{place}</MapTitle>
      <ResponsiveMap>
        <iframe
          title="map"
          width="630"
          height="300"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          allowFullScreen
          src={url}
        ></iframe>
      </ResponsiveMap>
    </div>
  )
}
